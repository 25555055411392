import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ListItem from './list-item'

class Listing extends Component {
    render() {
        if (this.props.articles) {
            return (
                <div className="c-listing">
                    <div className="container">
                        <div className="row">
                            {this.props.articles.map((article, i) => {
                                return (
                                    <ListItem key={i} article={article} />
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

Listing.propTypes = {
    articles: PropTypes.array
}

export default Listing;