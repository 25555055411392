import React from 'react'
import useInView from "react-cool-inview";

const ListItem = ({ article }) => {
    const { observe, inView } = useInView();

    if (article) {
        return (
            <div className="c-listing__item" ref={observe}>
                <a className={inView ? "c-listing__item-link is-inview" : "c-listing__item-link"} href={article.url}>
                    {article.data.listing_image && article.data.listing_image.url ? 
                    <div className="c-listing__item-media-container">
                        <div className="c-listing__item-media-wrapper">
                            <img src={`${article.data.listing_image.url}&w=500`} alt={article.data.listing_image.alt ? article.data.listing_image.alt : article.data.title.text} className="c-blog-listing__item-media" loading="lazy"/>
                        </div>
                    </div> : null}
                    <h3 className="c-listing__item-title">{article.data.title.text}</h3>
                    <p className="c-listing__item-summary">{article.data.summary.text}</p>
                </a>
            </div>
        )
    } 
    
    return null
}

export default ListItem